import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Link from '../components/link'
import { Homepage } from '../utils/navLinks'

const NotFoundPage = () => {
    const { t } = useTranslation('error404')

    return (
        <Layout>
            <SEO
                title="error404:title"
                description="error404:message"
                image="/metaImages/error404.png"
            />
            <div className="errorPage">
                <h1 className="header">{t('header')}</h1>
                <p className="paragraph">{t('message')}</p>
                <Button
                    disableElevation
                    to={Homepage.path}
                    variant="contained"
                    component={Link}
                    className="containedButton"
                >
                    {t('link')}
                </Button>
            </div>
        </Layout>
    )
}

export default NotFoundPage
